import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Seo from "../components/SEO"
import ArrowWhite from "../assets/img/arrow-right.webp"
import ArrowBlack from "../assets/img/arrow-right-black.webp"
import { Link } from "gatsby"
const NotFoundPage = () => {
  return (
    <section className="pt-[7.625rem] pb-[4.5rem] md:pt-36 lg:pt-40 lg:pb-28 overflow-hidden">
      <div className="container">
        <div className="title-sec m-auto w-full text-center">
          <h1 className="text-black text-[2.25rem] md:text-[52px] leading-[3.125rem] lg:leading-normal mb-[1.688rem] md:mb-5 font-blancomedium">
            Page Not Found
            <span className="text-[#FF3D2E] w-2 h-2 rounded-full inline">.</span>
          </h1>
          <p className="text-base leading-[1.875rem] font-gorditaregular mb-[3.125rem]">
            We're sorry, the page you requested could not be found.  <br className="max-[455px]:hidden" />
            Please go back to the homepage
          </p>
        </div>
      </div>
      <StaticImage
        src="../assets/img/page-not-found-1920.webp"
        alt="page-not-found"
        className="w-full mb-10"
        placeholder="blurred"
      />
      <div className="container text-center">
        <Link
          to="/"
          className="inline-flex w-auto gap-[0.688rem] items-center bg-white-200 justify-center md:justify-start inline-white text-black border border-black shadow-1xl text-base leading-[1.313rem] rounded-3xl pl-6 py-1 pr-1 font-gorditamedium group ease-in duration-300 mt-10 md:mt-[3.125rem]"
        >
          Take Me Home
          <span className="inline-flex bg-primary justify-center items-center rounded-full relative h-[2.125rem] w-[2.125rem] overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent group-hover:border-primary">
            <img
              src={ArrowBlack}
              alt=""
              className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-16 ease-in duration-300 w-[7px] h-[14px]"
            />
            <img
              src={ArrowWhite}
              alt=""
              className="absolute -left-16 top-1/2 -translate-y-1/2 -translate-x-1/2 group-hover:left-1/2  group-hover:invert-[1] group-hover:brightness-[1] w-[7px] h-[14px] ease-in duration-300"
            />
          </span>
        </Link>
      </div>
    </section>
  )
}
export const Head = () => (
  <Seo title="Not found" description="This page is not available" />
)

export default NotFoundPage
